/**
 * only X inputs are allowed for free users
 */
const LIMIT_TOO_MANY_INPUTS = 'limit-too-many-inputs';

/**
 * only one file is allowed for free users
 */
const LIMIT_BATCH_IS_PREMIUM = 'limit-batch-is-premium';

/**
 * an individual input is too large
 */
const LIMIT_INPUT_TOO_LARGE = 'limit-input-too-large';

/**
 * all inputs combined are too big
 */
const LIMIT_JOB_TOO_LARGE = 'limit-job-too-large';

/**
 * conversion option is only available for premium users, but no more specific, matching limit was found
 */
const LIMIT_GENERIC_CONVERSION_OPTION_IS_PREMIUM = 'limit-generic-conversion-option';

/**
 * ocr is only available for premium users
 */
const LIMIT_OCR_IS_PREMIUM = 'limit-ocr-is-premium';

/**
 * number of uses of feature is limited for free users
 */
const LIMIT_FEATURE_TOO_MANY_JOBS = 'limit-feature-too-many-jobs';

/**
 * global number of uses/conversions is limited for free users
 */
const LIMIT_GLOBAL_TOO_MANY_JOBS = 'limit-global-too-many-jobs';

/**
 * global number of concurrent conversions is limited for free users
 */
const LIMIT_GLOBAL_TOO_MANY_CONCURRENT_CONVERSIONS = 'limit-global-too-many-concurrent-conversions';

/**
 * feature is only available for premium users
 */
const LIMIT_FEATURE_IS_PREMIUM = 'limit-feature-is-premium';

/**
 * used if no other message is applicable
 */
const LIMIT_GENERIC = 'limit-generic';

/**
 * Credits for user are consumed
 */
const LIMIT_CREDITS_CONSUMED = 'limit-credits-consumed';

/**
 * used for cases where we get a 402 response but no reason why
 *
 * if we write only good code we won't need to use this
 */
const LIMIT_UNKNOWN = 'limit-unknown';

export { LIMIT_TOO_MANY_INPUTS };
export { LIMIT_BATCH_IS_PREMIUM };
export { LIMIT_INPUT_TOO_LARGE };
export { LIMIT_JOB_TOO_LARGE };
export { LIMIT_GENERIC_CONVERSION_OPTION_IS_PREMIUM };
export { LIMIT_OCR_IS_PREMIUM };
export { LIMIT_FEATURE_TOO_MANY_JOBS };
export { LIMIT_GLOBAL_TOO_MANY_JOBS };
export { LIMIT_FEATURE_IS_PREMIUM };
export { LIMIT_GENERIC };
export { LIMIT_UNKNOWN };
export { LIMIT_GLOBAL_TOO_MANY_CONCURRENT_CONVERSIONS };
export { LIMIT_CREDITS_CONSUMED };

const LIMITS = [
    LIMIT_TOO_MANY_INPUTS,
    LIMIT_BATCH_IS_PREMIUM,
    LIMIT_INPUT_TOO_LARGE,
    LIMIT_JOB_TOO_LARGE,
    LIMIT_GENERIC_CONVERSION_OPTION_IS_PREMIUM,
    LIMIT_OCR_IS_PREMIUM,
    LIMIT_FEATURE_TOO_MANY_JOBS,
    LIMIT_GLOBAL_TOO_MANY_JOBS,
    LIMIT_FEATURE_IS_PREMIUM,
    LIMIT_GENERIC,
    LIMIT_UNKNOWN,
    LIMIT_GLOBAL_TOO_MANY_CONCURRENT_CONVERSIONS,
    LIMIT_CREDITS_CONSUMED,
];

export { LIMITS };
