import { defineStore } from 'pinia';
import { testIsNonEmptyString } from '../../../lib/test-and-assert/test-base';
import { userManager } from '../../user/user-manager';

const defaults = {
    isSubmitting: false,
    selectedProvider: null,
    selectedBilling: null,
    selectedProduct: null,
    selectedSize: null,
    selectedPlan: null,
    skipPayment: false,
    skipPlanSelect: false,
    onlyProAndUltimate: false,
    email: '',
    canGoBack:false
};

export const useUserRegisterPaymentModalStore = defineStore('userRegisterPaymentModalStore', {
    /**
     * @type {Object} state
     * @property {?String} openPage
     * @property {?String} lastOpenPage
     * @property {Boolean} isSubmitting
     * @property {?String} selectedSize
     * @property {Boolean} skipPlanSelect
     * @property {?String} selectedBilling
     * @property {?String} selectedProduct
     * @property {?String} selectedProvider
     * @property {?String} selectedPlan
     * @property {?String} selectedQuota
     * @property {Boolean} skipPayment
     * @property {Boolean} isSale
     * @property {String} currency
     * @property {Boolean} offerTrial
     * @property {Boolean} canGoBack
     */
    state: () =>
        Object.assign({}, defaults, {
            openPage: null,
            lastOpenPage: null,
            offerTrial: false,
            currency: 'usd',
            isSale: false,
        }),
    getters: {
        getOpenPage(state) {
            return state.openPage;
        },
        getSubmitting(state) {
            return state.isSubmitting;
        },
    },
    actions: {
        setOpenPage(page) {
            if (this.openPage) {
                this.lastOpenPage = this.openPage;
            }

            this.openPage = page;
        },
        async openOnPage(page) {
            await userManager.getUser();

            this.setOpenPage(page);
        },
        close() {
            this.lastOpenPage = this.openPage;
            this.openPage = null;
        },
        changePage(payload) {
            this.openPage = payload.toPage;

            if (testIsNonEmptyString(payload.fromPage)) {
                this.lastOpenPage = payload.fromPage;
            }
        },
        resetDefaults() {
            for (const [key, value] of Object.entries(defaults)) {
                this[key] = value;
            }
        },
        setSubmitting(isSubmitting) {
            this.isSubmitting = isSubmitting;
        },
        setOfferTrial(offerTrial) {
            this.offerTrial = offerTrial;
        },
        setOnlyProAndUltimate(onlyProAndUltimate) {
            this.onlyProAndUltimate = onlyProAndUltimate;
        },
        setIsSale(isSale) {
            this.isSale = isSale;
        },
        setSelectedProvider(selectedProvider) {
            this.selectedProvider = selectedProvider;
        },
        setSelectedBilling(selectedBilling) {
            this.selectedBilling = selectedBilling;
        },
        setSelectedProduct(selectedProduct) {
            this.selectedProduct = selectedProduct;
        },
        setSelectedSize(selectedSize) {
            this.selectedSize = selectedSize;
        },
        setSelectedPlan(selectedPlan) {
            this.selectedPlan = selectedPlan;
        },
        setSelectedQuota(selectedQuota) {
            this.selectedQuota = selectedQuota;
        },
        setCurrency(currency) {
            this.currency = currency;
        },
        setSkipPayment(skipPayment) {
            this.skipPayment = skipPayment;
        },
        setSkipPlanSelect(skipPlanSelect) {
            this.skipPlanSelect = skipPlanSelect;
        },
        setEmail(email) {
            this.email = email;
        },
        setCanGoBack(canGoBack) {
            this.canGoBack = canGoBack;
        }
    },
});
